import "core-js/modules/es.array.push.js";
import { showToast } from 'vant';
import 'vant/es/toast/style';
import { mande } from 'mande';
import axios from 'axios';
let host = "http://localhost:3000";
const ping = mande(host + '/api/ping');

// axios.defaults.baseURL='http://localhost:3000'
axios.get("/mingyan").then(res => {
  console.log(res);
});
// wx.config({
//   debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
//   appId: 'wx469509deb2ec24da',
//   timestamp: new Date().getTime(),
//   nonceStr: "feng321123",
//   signature: "ca",
//   jsApiList: ['updateTimelineShareData', 'updateAppMessageShareData', 'openLocation', 'getLocation', 'hideMenuItems', 'chooseImage', 'previewImage', 'uploadImage'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
// });
export default {
  data() {
    return {
      goods: {
        title: '美国伽力果（约680g/3个）',
        price: 2680,
        express: '免运费',
        remain: 19,
        thumb: ['https://img.yzcdn.cn/public_files/2017/10/24/e5a5a02309a41f9f5def56684808d9ae.jpeg', 'https://img.yzcdn.cn/public_files/2017/10/24/1791ba14088f9c2be8c610d0a6cc0f93.jpeg']
      }
    };
  },
  methods: {
    formatPrice() {
      return '¥' + (this.goods.price / 100).toFixed(2);
    },
    onClickCart() {
      this.$router.push('cart');
    },
    sorry() {
      showToast('暂无后续逻辑~');
      ping.get("", {
        some: "feng"
      });
    },
    scan: function () {
      var _this = this;
      var url = encodeURIComponent(location.href.split('#')[0]);
      axios.get('/wechat/web/pay', {
        url: url
      }).then(function (resp) {
        showToast('first:' + JSON.stringify(resp.data));
        function onBridgeReady() {
          WeixinJSBridge.invoke('getBrandWCPayRequest', resp.data, function (res) {
            showToast('结果:' + res);
            if (res.err_msg == "get_brand_wcpay_request:ok") {}
          });
        }
        if (typeof WeixinJSBridge == "undefined") {
          // showToast("....456")
          if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
          } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
            document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
          }
        } else {
          showToast("....123");
          onBridgeReady();
        }
      });
    },
    scan1: function () {
      var _this = this;
      var url = encodeURIComponent(location.href.split('#')[0]);
      axios.get('/wechat/web/pay', {
        url: url
      }).then(function (resp) {
        var result = resp.data;
        console.log(result);
        wx.config({
          debug: true,
          // 开启调试模式
          appId: result.appId,
          // 必填，公众号的唯一标识,这是视觉的appid
          timestamp: result.timestamp,
          // 必填，生成签名的时间戳
          nonceStr: result.nonceStr,
          // 必填，生成签名的随机串
          signature: result.signature,
          // 必填，签名，见附录1
          jsApiList: ['scanQRCode', 'chooseWXPay'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2,微信扫一扫
        });
        // wxconfig(result.timestamp, result.noncestr, result.signature);
        wx.ready(function () {
          wx.chooseWXPay({
            timestamp: result.timestamp,
            // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
            nonceStr: '',
            // 支付签名随机串，不长于 32 位
            package: '',
            // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
            signType: '',
            // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
            paySign: '',
            // 支付签名
            success: function (res) {
              // 支付成功后的回调函数
            }
          });
        });
      }).catch(function (error) {
        console.log(error);
      });
      function wxconfig() {
        /* 处理失败验证 */
        wx.error(function (res) {
          _this.$toast("配置验证失败");
        });
      }
    }
  }
};